import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['quotationsFormBatch']

  connect() {
    document.addEventListener("turbo:before-fetch-request", function(e) {
        Turbo.navigator.delegate.adapter.showProgressBar();
    })
    document.addEventListener("turbo:frame-render", function(e) {
        Turbo.navigator.delegate.adapter.progressBar.hide()
    })
  }

  disconnect() {
    //
  }
}
